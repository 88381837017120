import Echo from '@ably/laravel-echo';
import * as Ably from 'ably';

window.Ably = Ably;

window.Echo = new Echo({
	broadcaster: 'ably',
	key: import.meta.env.VITE_WHATSAPP_ABLY_KEY,
});

window.Echo.connector.ably.connection.on((stateChange) => {
	if (
		stateChange.current === 'disconnected' &&
		stateChange.reason?.code === 40142
	) {
		// key/token status expired
		console.log(
			'LOGGER:: Connection token expired https://help.ably.io/error/40142'
		);
	}
});
